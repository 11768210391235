/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import BaseLayoutSetup from '../layouts/BaseLayoutSetup';

function IndexPage({ location }) {
  const [isArticleVisible, setIsArticleVisible] = useState(false);
  const [timeout, setTimeout] = useState(false);
  const [articleTimeout, setArticleTimeout] = useState(false);
  const [article, setArticle] = useState('');
  const [loading, setLoading] = useState('is-loading');

  let wrapperRef = useRef(null);

  const handleOpenArticle = art => {
    setIsArticleVisible(!isArticleVisible);
    setArticle(art);

    setTimeout(() => {
      setTimeout(!timeout);
    }, 325);

    setTimeout(() => {
      setArticleTimeout(!articleTimeout);
    }, 350);
  };

  const handleCloseArticle = event => {
    console.log('handleCloseArticle!', event);
    setArticleTimeout(!articleTimeout);

    setTimeout(() => {
      setTimeout(!timeout);
    }, 325);

    setTimeout(() => {
      setIsArticleVisible(!isArticleVisible);
      setArticle('');
    }, 350);
  };

  const handleClickOutside = event => {
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      if (isArticleVisible) {
        handleCloseArticle();
      }
    }
  };

  useEffect(() => {
    console.log('component mounted!');
    const timeoutId = setTimeout(() => {
      setLoading('');
    }, 100);
    document.addEventListener('mousedown', handleClickOutside);

    // equiv of ComponentWillUnmount()
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const setWrapperRef = node => {
    wrapperRef = node;
  };

  return (
    <BaseLayoutSetup
      location={location}
      loading={loading}
      isArticleVisible={isArticleVisible}
      handleOpenArticle={handleOpenArticle}
      timeout={timeout}
      articleTimeout={articleTimeout}
      article={article}
      handleCloseArticle={handleCloseArticle}
      setWrapperRef={setWrapperRef}
    />
  );
}

IndexPage.propTypes = {
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.objectOf(PropTypes.bool),
  }),
};

export default IndexPage;
